import React from 'react'
import { Link, graphql } from 'gatsby'
import { MDXRenderer } from 'gatsby-plugin-mdx'

import Layout from '../components/Layout';
import SEO from '../components/seo';
import '@styles/blog-styles.scss';

class BlogPostTemplate extends React.Component {
  render() {
    const post = this.props.data.mdx
    const siteTitle = this.props.data.site.siteMetadata.title
    const { previous, next } = this.props.pageContext
    // console.log(this.props.pageContext)
    const tags = post.frontmatter?.tags?.split(",").map((tag) => tag.trim()) || [];

    return (
      <Layout 
        location={this.props.location} 
        title={siteTitle}
        seo={
          <SEO title={post.frontmatter.title} description={post.excerpt} />
        }
      >
        <div className="blog-container">
        <Link 
          className="blog-back-button-container"
          to="/"
        >
          {'Back'}
        </Link>
        <h1 className="blog-title">{post.frontmatter.title}</h1>
        <h3 className="blog-subtitle">{post.frontmatter.subtitle}</h3>
        <div className="blog-tag-container">
          {
            tags.map((tag) => (
              <div className="blog-tag">
                {tag}
              </div>
            ))
          }
        </div>
        {/* <p>
          {post.frontmatter.date}
        </p> */}
        <div className="blog-content-container">
          <MDXRenderer>{post.body}</MDXRenderer>
        </div>
        </div>
      </Layout>
    )
  }
}

export default BlogPostTemplate

export const pageQuery = graphql`
  query($slug: String!) {
    site {
      siteMetadata {
        title
        author
      }
    }
    mdx(fields: { slug: { eq: $slug } }) {
      id
      excerpt(pruneLength: 160)
      frontmatter {
        title
        subtitle
        tags
        date(formatString: "MMMM DD, YYYY")
      }
      body
    }
  }
`
